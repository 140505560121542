import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Futurespective`}</h1>
    <h4>{`Sept. 20–Nov. 1, 2019 | Portland, ME`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAAENUlEQVQ4yx3Pi1PTBQDA8V9XmRqiA+vADDAT85LzysurgyxPUxQ5juq0zEQtL+U8ITVfpEF2BfjCxwJRgY3xGLixseHYg7EXjMGcjI03xuPAYWFiPvLOs293/gHf+9xXcNVV0aKV46lXYqgoRFWcR7OmmnaTDkdtNc1aFXatCmutmqZrdXhMBtwGHearMvQVRRjkErRll1BLxVgUEgR1SR5q6QWqC3OpuJjLtfJ8TPJCWnQqvBYjFkUpNRIxkoIzVBbnIyvMw1FbRaOiFLOiFEWxGEXR71RdPoP03DEEfUUBHoOK63o1Hn0NSkk+8itncdcr6bE3PFM7G+vpsZrwmevptJvpd1rpshnxW014G40MuhzYVWXYVDIER20Fusor2LUKTAoJR4/u5XjmIayqMuyaaiw1ZZgVUhoUMmzqSryN9TTXKdDJi541dm0NPouOfqeNoVYnglUlx65R0qyrpc1wjZxff0YjL6dFr6G9oY5ehwFfYx0+s5bBtiaG2hz4rQ10Oaz4bFbUZTJ8NgtD7lZG2z0InTYrw9fdjLW3M9DipF6pxKTRctPl4k6HnX//aONht4UHHUaeBHp5MtpFoL2VQfd1/DYbRqWSPmcLt/1+Jvt7EW46bUz0dnFv6CaBDi/+Vhceh4MJvw9GbsC9AR7f6uTR0A0e3erlwYCHEbeTiZ4eXIZ62oxGxtq9/DMywsPxcYRht4O/+nzcHe5nsr+PvlYXf3Z30+f3I9G70WjM3L/VwdOJAR4PuLDXlqGskDLo9dLd5sJtMjHZP8DdwDh3bo8j9OqkDLmb6GluJOD30uVw8GRklJLyMkRpl9h61s5/AS/DHj39Jhlncw5ScLGAQF8XHQ4rfc5m7g8O8vTBQ/4eG0X4ZedqFOczuZx9hBOZBzh36iTKkstsXBXH7pQNFIlPoJNd4HjGfkrzT3IsK43Mw3uZ7Gki47tNfPP1Rg7t34XbbuP+2BjC2g/nU34gAf/V01Rl70JblMO+1J1MCwpGVVzI6hVLSFr3ATGxy1iVsJLf0jeTFhtGQ/F5tn6eRNDU53l1tojohdH4HBYE6anDGCXZOAt+4EhKAgfSt7A+8RMEQWDxokjeeS+SBYvCmLcwguT45WxeE8fGd+ewbsVSpoWEMD08gpdnzCI4OJjC07kIOanJnDr4LdvXvU9oiIiYZYuJX/sRLwjP8UaUiNA5QSxYEEllXgZtyosYy0u4dCab9PUfk/TKTPZsS2VpbCzTXprCp4nrEWrEWZxI38KSN8NZNC+clE0JJG6IRwiaSlhECNODp/BZwnJ+2reDY2kpHEzbzleJaxC/HYX0dRGVb0UwXzTz2dEXyUkIJrmY6vwsju5OZtuXcezYlcyehDh+nBtKTNRsQsNFxK9czLLlMcTGRhMZM5eoWS+SERZE7pwZXHhtFgtnzyQ6OpKs71P5H+BuOh8dOSUtAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Futurespective",
          "title": "Futurespective",
          "src": "/static/8dc36e70334337b83e97d6f4ecc30618/c1b63/futurespective-conviv.png",
          "srcSet": ["/static/8dc36e70334337b83e97d6f4ecc30618/5a46d/futurespective-conviv.png 300w", "/static/8dc36e70334337b83e97d6f4ecc30618/0a47e/futurespective-conviv.png 600w", "/static/8dc36e70334337b83e97d6f4ecc30618/c1b63/futurespective-conviv.png 1200w", "/static/8dc36e70334337b83e97d6f4ecc30618/fbf76/futurespective-conviv.png 1252w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://www.meca.edu/about/institute-of-contemporary-art/past-exhibitions/di-futurespective/" title="Link to MECA Site" mdxType="Outcomeitem" />
    <Outcomeitem link="https://www.pressherald.com/2019/11/10/art-review-designinquirys-overwhelming-show-has-a-welcoming-core/" title="Press" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`We do our best work when we’re together and everything is a project. These are on page one of DesignInquiry’s operating manual. With invitations to different minds, different practices, and different approaches, Futurespective reveals processes of design with collaborative hands-on and on-site making, curating, and exhibiting of selected work using the topic of currency (understood as value) as a prompt for a myriad of design interpretations throughout the duration of the exhibition.`}</strong></p>
    <p><strong parentName="p">{`Futurespective is a series of installations that demonstrate how DesignInquiry engages in open-ended extra-disciplinary exchange. On display in the galleries are multi-media installations of objects + projects that present the experience of DesignInquiry as a real-time gathering and explore the process of DesignInquiry as a collective practice of thinking and making.`}</strong></p>
    <p><strong parentName="p">{`Futurespective demonstrates how our practices unfold in design and everywhere. These practices are as diverse as the Inquirers themselves: we draw, write, weave, and cook; we discuss, debate, and document. Through these practices, which are conceptual as well as material, we query the way design informs culture and culture informs design.`}</strong></p>
    <p><strong parentName="p">{`Events, visiting artists, and designers-in-residence intersect with Maine College of Art  faculty, students and the Portland community. Futurespective is an invitation to witness and participate in an active design lab with the goal to make evident that while a design process may appear unfinished, unplanned, and unknown, the outcomes can surprise in productive ways that reframe initial expectations of success.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      